import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-form"
export default class extends Controller {
  static targets = [ 'treatment', 'prefecture', 'tel', 'user_name', 'cemetery_name', 'submit_btn' ]

  connect() {
    console.log( 'load simple_form' )
  }

  validate(){
    var check = {
      treatment: false,
      prefecture: false,
      tel: false,
      user_name: false,
      cemetery_name: true
    }

    // 年齢
    if ( this.treatmentTarget.value ) {
      check.treatment = true
    } else {
      check.treatment = false
    }

    // 貯金額
    if ( this.prefectureTarget.value ) {
      check.prefecture = true
    } else {
      check.prefecture = false
    }

    // 電話番号
    var tel = this.telTarget.value.replace( /-/g, '' )
    if ( ! tel.match( /(.)\1{4,}/ ) ) {
      if ( tel.match( /^0[789]0/ ) ) {
        if ( /^0[789]0[0-9]{8}$/.test( tel ) && ! /^08012345678$/.test( tel ) ) {
          check.tel = true
        } else {
          check.tel = false
        }
      } else {
        if ( /^0[1-9]\d{8}$/.test( tel ) ) {
          check.tel = false
        }
      }
    } else {
      check.tel = false
    }

    // ユーザー名
    if ( this.user_nameTarget.value ) {
      check.user_name = true
    } else {
      check.user_name = false
    }

    var falseKey = '';

    var checkResult = Object.keys( check ).every( function( key ) {
      if ( ! check[key] ) {
        falseKey = key
      }
      return check[key]
    })

    var row_items = document.getElementsByClassName( 'form_ele' )
    Array.from(row_items).forEach(function( element ){
      element.classList.remove( 'blink' )
    })

    if ( checkResult ) {
      this.submit_btnTarget.classList.remove( 'is-disable' )
      this.submit_btnTarget.disabled = false
    } else {
      eval( 'this.' + falseKey + 'Target.classList.add( "blink" )')
      this.submit_btnTarget.classList.add( 'is-disable' )
      this.submit_btnTarget.disabled = true
    }
  }
}