/*
* scroll
*/
import jQuery from "jquery"

$(function(){
   // #で始まるアンカーをクリックした場合に処理
   $('a[href^="#"]').click(function() {
      // スクロールの速度
      var speed = 300; // ミリ秒
      // アンカーの値取得
      var href= $(this).attr("href");
      // 移動先を取得
      var target = $(href == "#" || href == "" ? 'html' : href);
      // 移動先を数値で取得
      var position = target.offset().top;
      // スムーススクロール
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
   });
});

/*
* css swicher
*/
function css_browser_selector(u){
	var ua=u.toLowerCase(),
	is=function(t){return ua.indexOf(t)>-1},
	e='edge',g='gecko',w='webkit',s='safari',o='opera',m='mobile',
	h=document.documentElement,
	b=[
		( !(/opera|webtv/i.test(ua)) && /msie\s(\d)/.test(ua))? ('ie ie'+RegExp.$1) :
			!(/opera|webtv/i.test(ua)) && is('trident') && /rv:(\d+)/.test(ua)? ('ie ie'+RegExp.$1) :
			is('edge/')? e:
			is('firefox/2')?g+' ff2':
			is('firefox/3.5')? g+' ff3 ff3_5' :
			is('firefox/3.6')?g+' ff3 ff3_6':is('firefox/3')? g+' ff3' :
			is('gecko/')?g:
			is('opera')? o+(/version\/(\d+)/.test(ua)? ' '+o+RegExp.$1 :
			(/opera(\s|\/)(\d+)/.test(ua)?' '+o+RegExp.$2:'')) :
			is('konqueror')? 'konqueror' :
			is('blackberry')?m+' blackberry' :
			is('android')?m+' android' :
			is('chrome')?w+' chrome' :
			is('iron')?w+' iron' :
			is('applewebkit/')? w+' '+s+(/version\/(\d+)/.test(ua)? ' '+s+RegExp.$1 : '') :
			is('mozilla/')? g:
			'',
			is('j2me')?m+' j2me':
			is('iphone')?m+' iphone':
			is('ipod')?m+' ipod':
			is('ipad')?m+' ipad':
			is('mac')?'mac':
			is('darwin')?'mac':
			is('webtv')?'webtv':
			is('win')? 'win'+(is('windows nt 6.0')?' vista':''):
			is('freebsd')?'freebsd':
			(is('x11')||is('linux'))?'linux':
			'',
			'js'];
	c = b.join(' ');
	h.className += ' '+c;
	return c;
};
css_browser_selector(navigator.userAgent);





/*
* responsive image
*/
$(function() {
  var $elem = $('.switch');
  var sp = '_sp.';
  var pc = '_pc.';
  var replaceWidth = 768;

  function imageSwitch() {
    var windowWidth = parseInt($(window).width());

    $elem.each(function() {
      var $this = $(this);
      if(windowWidth >= replaceWidth) {
        $this.attr('src', $this.attr('src').replace(sp, pc));

      } else {
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }

	  $this.fadeIn();
    });
  }
  imageSwitch();

  var resizeTimer;
  $(window).on('resize', function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      imageSwitch();
    }, 100);
  });
});


/*
* accordion
*/

$(function () {
$('.accordion .acc_cont').hide();
  $('.acc_btn').click(function () {
    $(this).next('.acc_cont').slideToggle();
    $(this).find(".icon").toggleClass('open');
  });
});


/*
 * matchHeight
 */
$(function() {
    $('#sec07 .inner').matchHeight();
    $('#sec07 .inner .sub_ttl').matchHeight();
    $('#sec06 .svs_inn').matchHeight();
  });
  
  
/*
 * sm hover無効
 */
  
var touch = 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

if(touch) {
  try {
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}


$(function() {
	function setBlink() {
		$('.form_area form').each(function() {
			if($(this).find('select[name="consttype"]').val() === '') {
				$(this).find('select[name="pref"]').removeClass('blink');
				$(this).find('input[name="tel"]').removeClass('blink');
				$(this).find('.form_btn').removeClass('blink');
				$(this).find('select[name="consttype"]').addClass('blink');
			}
			else if($(this).find('select[name="pref"]').val() === '') {
				$(this).find('select[name="consttype"]').removeClass('blink');
				$(this).find('input[name="tel"]').removeClass('blink');
				$(this).find('.form_btn').removeClass('blink');
				$(this).find('select[name="pref"]').addClass('blink');
			}
			else if($(this).find('input[name="tel"]').val() === '') {
				$(this).find('select[name="consttype"]').removeClass('blink');
				$(this).find('select[name="pref"]').removeClass('blink');
				$(this).find('.form_btn').removeClass('blink');
				$(this).find('input[name="tel"]').addClass('blink');
			}
			else {
				$(this).find('select[name="consttype"]').removeClass('blink');
				$(this).find('select[name="pref"]').removeClass('blink');
				$(this).find('input[name="tel"]').removeClass('blink');
				$(this).find('.form_btn').addClass('blink');
			}
		});
	}

	setBlink();

	$('select').change(function() {
		setBlink();
	});

	$('input[name="tel"]').keyup(function() {
		setBlink();
	});
});
