import { Controller } from "@hotwired/stimulus"
import Select2 from "select2"
import jpostal from "jquery-jpostal-ja"

// Connects to data-controller="admin"
export default class extends Controller {
  static targets = [ 'transport' ]
  static values = {
    id: Number
  }

  connect() {
    Select2();
    $( '.select-2' ).select2({
      width: '100%',
      lang: 'ja',
    });

    $('[name="conversion[zipcode]"]').jpostal({   
      postcode : [
        '[name="conversion[zipcode]"]',
      ],
      address : {
        '[name="conversion[prefecture_id]"]' : '%3',
        '[name="conversion[address]"]' : '%4%5',
      }
    });
  }

  transport() {
    document.getElementById('conversion_company_id').value = this.idValue
  }
}
