import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-form"
export default class extends Controller {
  static targets = [ 'original', 'btn', 'display_name' ]

  connect() {
    console.log( 'load cp_admin' )
  }

  paste(){
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth()+1;
    var date = now.getDate();
    var hour = now.getHours();
    var min = now.getMinutes();
    var sec = now.getSeconds();
    var time_format = year + '/' + month.toString().padStart(2, "0") + '/' + date.toString().padStart(2, "0") + ' ' + hour.toString().padStart(2, "0") + ':' + min.toString().padStart(2, "0") + ':' + sec.toString().padStart(2, "0");
    var admin_name = this.display_nameTarget.value
    var original = this.originalTarget.value


    var add_text = ""
    if ( original !=  "" ) {
      add_text += "\n"
    }
    add_text += time_format + '【' + admin_name + '】'

    this.originalTarget.value = original + add_text
  }
}